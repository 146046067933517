<template>
  <div>
    <div v-if="isCheckout" class="mt-4 mb-md-6 pa-3 pa-sm-0">
      <div no-gutters align-content="center" class="alternatives ">
        {{ cart.user.ssoUser }}
        <v-checkbox
          dense
          color="primary"
          hide-details
          v-model="acceptAlternatives"
          class="accept-alternatives mt-0 pt-0 mr-2"
        >
          <template v-slot:label>
            <span>{{ $t("cart.acceptAlternatives") }}</span>
            <v-tooltip
              top
              :open-on-hover="false"
              :open-on-click="true"
              max-width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  x-small
                  plain
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="grey">
                    mdi-information-outline
                  </v-icon></v-btn
                >
              </template>
              <span>{{ $t("cart.acceptAlternativesTooltip") }}</span>
            </v-tooltip>
          </template>
        </v-checkbox>
      </div>

      <div class="notes pt-4">
        <v-textarea
          v-model="orderComment"
          :maxlength="1000"
          :label="$t('cart.notes.label')"
          :placeholder="$t('cart.notes.placeholder')"
          @blur="setCartInfo({ order_comment: tempOrderComment })"
          no-resize
          rows="3"
          outlined
          @keydown.enter.prevent="() => {}"
          @paste.prevent="() => {}"
        ></v-textarea>
      </div>
    </div>
    <v-row
      no-gutters
      v-if="!$vuetify.breakpoint.xsOnly && isCheckout"
      class="table-header"
    >
      <v-col cols="6" sm="4" lg="5" xl="6" class="d-flex justify-start">
        {{ $t("checkout.tableHeader.product") }}
      </v-col>

      <v-col cols="2" sm="2" lg="2" xl="2" class="d-flex justify-center">
        {{ $t("checkout.tableHeader.unitPrice") }}
      </v-col>
      <v-col cols="2" sm="3" lg="3" xl="2" class="d-flex justify-center">
        {{ $t("checkout.tableHeader.quantity") }}
      </v-col>
      <v-col cols="2" sm="3" lg="2" xl="2" class="d-flex justify-center">
        {{ $t("checkout.tableHeader.totalPrice") }}
      </v-col>
    </v-row>
    <v-list
      id="cartItemList"
      flat
      subheader
      dense
      class="cart-item-list pt-5"
      :class="{ 'checkout-item-list': isCheckout }"
    >
      <!-- three-line -->

      <template v-if="!isCheckout">
        <!-- <v-divider /> -->
        <v-subheader
          class="cart-item-count mx-5 d-flex justify-space-between mb-2"
        >
          <CartItemCount :count="cart.totalItems" />
          <v-btn
            plain
            class="empty-cart-btn"
            :disabled="cart.totalItems == 0"
            @click="emptyCartConfirm"
            >Svuota il carrello</v-btn
          >
        </v-subheader>
        <!-- <v-divider /> -->
      </template>

      <div :class="{ 'cart-item-list-no-checkout': !isCheckout }">
        <div class="flex align-center">
          <CartItem
            :item="cartItem"
            v-for="cartItem in cart.cartItems"
            :key="cartItem.cartItemId"
            :full="full"
            :isCheckout="isCheckout"
          />
        </div>
      </div>
    </v-list>
  </div>
</template>
<script>
import CartItem from "./CartItem";
import CartItemCount from "./CartItemCount";

import { mapActions, mapGetters, mapState } from "vuex";
import get from "lodash/get";
import reduce from "lodash/reduce";

export default {
  name: "CartItemList",
  components: { CartItem, CartItemCount },
  props: ["full", "isCheckout"],
  data() {
    return {
      panel: [],
      showSectors: null,
      tempOrderComment: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({ isSsoUser: "cart/isSsoUser" }),
    acceptAlternatives: {
      get() {
        let value = true;
        this.cart.cartItems.forEach(cartItem => {
          if (
            get(cartItem, "cartItemInfo.accept_alternatives", "true") == "false"
          ) {
            value = false;
          }
          return value;
        });
        reduce(
          this.cart.cartItems,
          function(value, cartItem) {
            return (value && cartItem.cartItemInfo.accept_alternatives) || true;
          },
          true
        );
        // this.cart.cartItems.forEach(cartItem => {
        //   if (
        //     get(cartItem, "cartItemInfo.accept_alternatives", "true") ==
        //     "false"
        //   ) {
        //     value = false;
        //   }
        //   return value;
        // });
        return value;
      },
      set(value) {
        this.setCartItemInfo({
          items: this.cart.cartItems,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    orderComment: {
      get() {
        return this.cart.cartInfos.order_comment;
      },
      set(value) {
        this.tempOrderComment = value;
      }
    }
  },
  methods: {
    ...mapActions({
      emptyCart: "cart/emptyCart",
      setCartItemInfo: "cart/setCartItemInfo",
      setCartInfo: "cart/setCartInfo"
    }),
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    }
  },
  mounted() {
    this.showSectors = global.config.showSectors;
    if (global.config.showSectors) {
      if (this.cart && this.cart.sectors) {
        this.panel = this.cart.sectors.map((k, i) => i);
      }
    }
  },
  watch: {
    cart() {
      if (global.config.showSectors) {
        if (this.cart && this.cart.sectors) {
          this.panel = this.cart.sectors.map((k, i) => i);
        }
      }
    }
  }
};
</script>
