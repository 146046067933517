<template>
  <v-layout
    justify-center
    align-center
    class="secondary px-5 pt-1 search-panel d-flex flex-column"
  >
    <v-icon x-large color="white">$search</v-icon>
    <h3 class="mt-1 mb-3 white--text">{{ $t("navbar.searchPlaceholder") }}</h3>
    <SearchInput
      :placeholder="''"
      append-icon="$search"
      :focusOnOpen="true"
      @closeSearchModal="closeSearchModal"
    />
  </v-layout>
</template>

<script>
import SearchInput from "./SearchInput";
export default {
  components: { SearchInput },
  methods: {
    closeSearchModal() {
      this.$emit("submit", true);
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  h3 {
    color: $white;
  }
  .autocomplete {
    input {
      text-align: center !important;
    }
  }
}
</style>
