<template>
  <div class="product-price">
    <div
      class="price_block"
      v-if="product.priceStandardDisplay || product.priceDisplay"
    >
      <!-- <div class="old-price" v-if="product.priceStandardDisplay">
        {{ $n(product.priceStandardDisplay, "currency") }}
      </div> -->
      <div
        class="cur-price"
        :class="{ 'promo-price': product.priceStandardDisplay }"
        itemprop="price"
      >
        {{ $n(product.priceDisplay, "currency") }}
        <span
          class="weight-unit"
          v-if="product.productInfos.TIPOLOGIA != 'Pezzo'"
          >/{{ product.weightUnitDisplay }}</span
        >
      </div>
    </div>
    <div class="product-descr">
      <span
        class="weight-unit"
        v-if="
          product.productInfos &&
            product.productInfos.TIPOLOGIA == 'Pezzo' &&
            product.priceUmDisplay
        "
      >
        {{ $n(product.priceUmDisplay, "currency") }}/{{
          product.productInfos.WEIGHT_UNIT_SELLING
        }}
        {{ ivaCategory }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductPrice",
  props: ["product"],
  computed: {
    ivaCategory() {
      return this.product.ivaCategory && this.product.ivaCategory.descr
        ? " - " + this.product.ivaCategory.descr
        : "";
    }
  }
};
</script>
