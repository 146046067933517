<template>
  <v-list flat subheader dense class="py-0">
    <v-list-item>
      <!-- <v-list-item-avatar
        class="mr-1 my-1 d-md-none d-lg-inline d-xl-inline"
        rounded="0"
      >
        <img aria-hidden :src="serviceImg" />
      </v-list-item-avatar> -->

      <v-list-item-content>
        <v-list-item-title v-if="!isCart">
          <span class="service-name">{{ serviceName }}</span>
        </v-list-item-title>
        <v-list-item-subtitle class="d-flex flex-column align-left">
          <v-row no-gutters class="d-flex align-center">
            <span class="row-label font-weight-bold">
              {{ $t("checkout.deliveryService.serviceSelected") }}
            </span>

            <v-btn
              class="cart-info-button"
              :disabled="!editableAddress"
              text
              tile
              small
              elevation="0"
              rounded
              @click="openAddressSelector"
            >
              <img class="ml-0 ml-sm-2" :src="serviceImg" />
              <span class="cart-info-service-name-label service-name ml-2"
                >&nbsp;{{ serviceName }}</span
              >
              <span v-if="editableAddress" class="edit ml-2"
                >({{ $t("cart.cartInfoList.edit") }})</span
              >
            </v-btn>
          </v-row>

          <v-row no-gutters class="d-flex align-center">
            <span class="row-label" v-if="cart.shippingAddress">
              {{
                $t(
                  `checkout.deliveryService.addressSelected1.${cart.shippingAddress.deliveryServiceId}`
                )
              }}
            </span>

            <v-btn
              class="cart-info-button"
              :disabled="!editableAddress"
              text
              tile
              small
              elevation="0"
              rounded
              @click="openAddressSelector"
            >
              <span class="cart-info-button-label ml-0 ml-sm-2">{{
                shippingAddress
              }}</span>
              <span v-if="editableAddress" class="edit ml-2"
                >({{ $t("cart.cartInfoList.edit") }})</span
              >
            </v-btn>
          </v-row>

          <v-row no-gutters class="d-flex align-center">
            <span class="row-label">
              {{ $t("checkout.deliveryService.timeslotSelected") }}
            </span>
            <v-btn
              class="cart-info-button"
              :disabled="!editableTimeslot"
              text
              tile
              small
              elevation="0"
              rounded
              @click="openTimeslotSelector"
            >
              <span class="cart-info-button-label">{{ timeslot }}</span>
              <span v-if="editableTimeslot" class="edit ml-2"
                >({{ $t("cart.cartInfoList.edit") }})</span
              >
            </v-btn>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<style lang="scss" scoped>
.row {
  min-height: 30px;
}
.service-name {
  padding: 0 8px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #583d3e;
}
.cart-info-button {
  text-transform: none !important;
  justify-content: left;
  padding: 0 8px !important;
  font-size: 13px;
  // height: 16px !important;
  height: 100% !important;
  letter-spacing: normal;
  .v-btn__content {
    max-width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-icon {
    font-size: 12px;
    height: 12px;
    width: 12px;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 0.5em;
    color: white;
    border: 1px solid white;
    background-color: var(--v-primary-base);
    padding: 3px;
  }
}
.row-label {
  font-weight: 700;
}

.cart-info-button-label {
  max-width: 330px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit {
  text-decoration: underline;
  font-weight: 700;
}

.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
  padding: 1px 0;
}
</style>
<script>
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
export default {
  name: "CartInfoList",
  mixins: [login, cartInfo],
  data() {
    return {
      maxWidth: 320,
      maxHeight: 60
    };
  },
  props: { isCart: { type: Boolean, required: false } }
};
</script>
