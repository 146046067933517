<template>
  <v-container
    class="menu d-flex flex-column"
    style="background-image: url('/building.svg')"
    @click="clicked"
  >
    <div class="header mb-5 d-flex justify-space-between align-center">
      <h3>{{ $t("links.title") }}</h3>
      <v-btn
        class="close-btn"
        large
        color="white"
        plain
        @click="$emit('close')"
      >
        x
      </v-btn>
    </div>
    <div
      class="link py-3"
      no-gutters
      v-for="(link, index) in links"
      v-bind:key="index"
    >
      <a :href="link.url">{{ link.title }}</a>
    </div>
  </v-container>
</template>

<script>
import clickHandler from "~/mixins/clickHandler";
export default {
  mixins: [clickHandler],
  props: ["links"],
  methods: {
    onLinkClick() {
      this.drawerLinks = !this.drawerLinks;
      this.$nextTick(() => {
        this.clicked();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.menu {
  color: $white;
  background: $primary;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom right;
  .header {
    max-height: 50px;
    h3 {
      color: $white;
      font-size: 14px;
      text-transform: uppercase;
    }
    button {
      .v-btn__content {
        font-size: 18px;
      }
    }
  }
  .link {
    // max-height: 50px;
    height: auto;
    a {
      color: $white;
      text-decoration: none;
    }
  }
}
</style>
