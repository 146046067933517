<template>
  <v-menu class="sso-user-card" bottom offset-y left v-if="isSsoUser">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="isSsoUser"
        v-bind="attrs"
        v-on.stop="on"
        medium
        elevation="0"
        text
        height="48"
        color="secondary"
        class="sso-btn"
      >
        <div class="d-flex flex-column align-center" style="max-width: 60px;">
          <v-icon medium>mdi-account-lock-open</v-icon>
          <div class="label profile-name text-uppercase">
            <span v-if="user && !$vuetify.breakpoint.xs">
              {{ user.person.company }}
            </span>
            <span v-else-if="!$vuetify.breakpoint.xs">
              ---
            </span>
          </div>
        </div>
      </v-btn>
    </template>
    <SsoUserMenu />
    <v-list-item v-if="user" class="white">
      <strong>{{ user.person.company }}</strong>
    </v-list-item>
    <v-divider class="divider"></v-divider>

    <slot #default></slot>
  </v-menu>
</template>
<style lang="scss">
.sso-btn {
  .profile-name {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media #{map-get($display-breakpoints, 'sm-only')} {
      width: 60px;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      width: 40px;
    }
    line-height: 14px;
    font-size: 10px;
  }
}
.sso-user-list {
}
</style>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import SsoUserMenu from "@/components/profile/SsoUserMenu.vue";
import split from "lodash/split";

export default {
  name: "SsoUserdCard",
  components: {
    SsoUserMenu
  },
  data() {
    return {
      userList: null
    };
  },
  computed: {
    ...mapGetters({
      isSsoUser: "cart/isSsoUser",
      user: "cart/getUser"
    }),
    ...mapState({
      category: ({ category }) => category.category
    })
  },
  methods: {
    ...mapActions({
      doSsoLogout: "cart/doSsoLogout",
      setCategory: "category/setCategory",
      resetFilters: "category/resetFilters"
    }),
    async ssoLogout() {
      const logoutResult = await this.doSsoLogout();
      if (logoutResult) {
        this.$router.push({ name: "Home" });
      }
    },
    async refresh() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });
      //await this.$router.go();
    }
  }
};
</script>
