let routes = [
  {
    path: "/sso",
    meta: { requiresSsoAuth: true },
    component: () => import("@/views/sso/ListUser.vue"),
    children: [
      {
        path: "list-user",
        name: "ListUser",
        meta: {
          // requiresSsoAuth: true,
          breadCrumb: "Lista Utenti"
        },
        component: () => import("@/views/profile/ProfileUpdate.vue")
      }
    ]
  }
];

export default routes;
