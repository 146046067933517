<template>
  <div class="promo-box d-flex" :class="[warehousePromo.view.cssClass]">
    <span v-html="warehousePromo.view.header" class="promo-header"></span>
    <span v-html="warehousePromo.view.body" class="promo-body"></span>
    <span v-html="warehousePromo.view.footer" class="promo-footer"></span>
  </div>
</template>
<script>
export default {
  name: "ProductPromo",
  props: { warehousePromo: { type: Object, required: true } }
};
</script>
