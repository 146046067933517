<template>
  <div class="cart-item ">
    <v-list-item
      :to="{
        name: 'Product',
        params: { slug: item.product.slug }
      }"
      class="cart-item-list-item "
    >
      <div class="d-flex promo-container mr-auto">
        <div
          class="promo-box text-uppercase product-badge novita mr-2"
          v-if="product.newProduct"
        >
          Novità
        </div>
        <ProductPromo
          v-if="item.product.warehousePromo"
          :warehousePromo="item.product.warehousePromo"
        />
      </div>
      <div class="d-flex flex-wrap align-center">
        <v-list-item-avatar
          :width="full ? '80' : '60'"
          :height="full ? '80' : '60'"
          rounded="0"
        >
          <img :src="item.product.mediaURL" :alt="'Immagine prodotto'" />
        </v-list-item-avatar>

        <v-list-item-content class="cart-item-content">
          <div class="descripion">
            <v-list-item-subtitle class="text-uppercase text-wrap">
              {{ item.product.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ item.product.description }}
            </v-list-item-subtitle>
            <v-list-item-title class="font-weight-bold">
              {{ item.product.shortDescr }}
            </v-list-item-title>
          </div>
          <ProductPrice
            :product="item.product"
            :showStandard="false"
            v-if="full"
          ></ProductPrice>
        </v-list-item-content>
        <v-list-item-action>
          <div :class="'actions d-flex align-center'">
            <div
              class="qty-wrap rounded-pill"
              :class="{ 'not-empty': quantity > 0 }"
              v-if="!showQntyInput"
            >
              <a
                role="button"
                class="minus"
                @click.prevent.stop="minus(true)"
                @mousedown.stop
              >
                <v-icon>mdi-minus</v-icon>
              </a>
              <div
                class="val-cnt"
                @click.stop.prevent=""
                @dblclick="toggleShowInput"
                v-long-press="300"
                @long-press-start="toggleShowInput"
                @mousedown.stop
              >
                <span class="val">{{ quantity }} {{ unit }}</span>
                <span class="small">{{ quantityPerUnit }}</span>
              </div>
              <a
                role="button"
                class="plus"
                @click.prevent.stop="plus(true)"
                @mousedown.stop
              >
                <v-icon v-if="quantity > 0">mdi-plus</v-icon>
                <v-icon v-else>$cart</v-icon>
              </a>
            </div>
            <div
              v-else
              class="qnty-manual-input rounded-md"
              :class="{ 'not-empty': quantity > 0 }"
              transition="fab-transition"
            >
              <a
                role="button"
                class="close"
                @click.stop.prevent="toggleShowInput(false)"
                @mousedown.stop
              >
                <v-icon>mdi-close</v-icon>
              </a>
              <v-tooltip
                bottom
                :open-on-hover="false"
                :open-on-click="true"
                max-width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="qntyInput"
                    ref="manualInput"
                    class="manual-input"
                    hide-details
                    autofocus
                    outlined
                    dense
                    v-mask="'###'"
                    type="number"
                    @click.stop.prevent=""
                    @keyup.enter="toggleShowInput(true, null, true)"
                    @keyup.esc="toggleShowInput(false)"
                    @mousedown.stop
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <span
                  class="qntity-tooltip"
                  v-if="
                    product.productInfos.QTY_LOCKED != 'true' ||
                      (product.productInfos.QTY_LOCKED == 'true' &&
                        !modulus(qntyInput))
                  "
                  v-html="
                    $t('product.quantity.confirmQntity', {
                      qntyInput: this.qntyInput
                    })
                  "
                  @click="clicked"
                >
                </span>
                <span
                  class="qntity-tooltip"
                  v-if="
                    product.productInfos.QTY_LOCKED == 'true' &&
                      modulus(qntyInput) &&
                      parseInt(qntyInput) <=
                        parseInt(this.product.productInfos.QTY_MULT)
                  "
                  v-html="
                    $t('product.quantity.editQntityMin', {
                      lowerInput: this.lowerInput
                    })
                  "
                  @click="clicked"
                >
                </span>

                <span
                  class="qntity-tooltip"
                  v-if="
                    product.productInfos.QTY_LOCKED == 'true' &&
                      modulus(qntyInput) &&
                      parseInt(qntyInput) >
                        parseInt(this.product.productInfos.QTY_MULT)
                  "
                  v-html="
                    $t('product.quantity.editQntityMinMax', {
                      lowerInput: this.lowerInput,
                      higherInput: this.higherInput
                    })
                  "
                  @click="clicked"
                >
                </span>
              </v-tooltip>
              <a
                role="button"
                class="check"
                @click.stop.prevent="toggleShowInput(true, null, true)"
                @mousedown.stop
              >
                <v-icon>mdi-check</v-icon>
              </a>
            </div>
            <div class="gross-total price">
              {{ $n(item.grossTotal, "currency") }}
            </div>
            <v-btn
              icon
              depressed
              class="remove-cart-item ml-4"
              aria-label="Rimuovi dal carrello"
              @click.prevent="remove"
              @mousedown.stop
            >
              <span v-if="!isCheckout">x</span>
              <v-icon color="grey" v-else>$delete</v-icon>
            </v-btn>
          </div>
        </v-list-item-action>
      </div>
    </v-list-item>

    <v-row
      v-if="isCheckout"
      no-gutters
      align-content="center"
      class="alternatives mt-2"
    >
      <v-checkbox
        dense
        color="primary"
        hide-details
        class="accept-alternatives mt-0 pt-0 mr-2"
        v-model="acceptAlternative"
      >
        <template v-slot:label>
          <span>{{ $t("cart.acceptAlternatives") }}</span>
        </template>
      </v-checkbox>
      <v-textarea
        v-if="isSsoUser"
        v-model="userNotes"
        @blur="
          setCartItemInfo({
            items: item,
            name: 'user_notes',
            value: tempUserNotes
          })
        "
        :maxlength="1000"
        :label="$t('cart.notes.itemNote')"
        no-resize
        class="mt-2"
        rows="2"
        outlined
        @keydown.enter.prevent="() => {}"
        @paste.prevent="() => {}"
      ></v-textarea>
    </v-row>
    <v-divider></v-divider>
    <v-dialog v-model="removeDialog" max-width="400">
      <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div>
      <v-card>
        <v-card-text class="body-1 pt-8">
          {{
            $t("message.remove", {
              name: this.product.name,
              quantity: this.quantity,
              unit: this.unit
            })
          }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            depressed
            color="green darken-1"
            text
            @click="removeDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            depressed
            text
            @click.prevent="doRemove"
            @mousedown.stop
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style global lang="scss">
.manual-input {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.qntity-tooltip {
  color: $primary;
  font-size: 13px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .cart-item-list-item {
    flex-wrap: wrap;
    justify-content: flex-end;

    .v-list-item__action {
      margin-left: 0 !important;
    }
    &::after {
      display: none !important;
    }
  }
  .cart-item-content {
    flex-grow: 1;
  }
  .cart-item .promo-container {
    // position: absolute;
    //top: -20px;
    //left: 0;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .cart-item-list-item {
    flex-direction: column;
    .promo-container {
      position: absolute;
      top: -10px;
      left: 10px;
      z-index: 3;
    }
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .cart-item-list-item {
    padding-left: 0;
    padding-right: 0;
    .v-list-item__action {
      width: 100%;
      justify-content: flex-end;
    }
  }
  .cart-item {
    .price_block {
      width: 60px !important;
    }
    .gross-total.price {
      width: 50px !important;
      font-size: 16px !important;
    }
    :deep(textarea) {
      font-size: 13px !important;
    }
  }
}

// @media #{map-get($display-breakpoints, 'xs-only')} {
//   span {
//     display: block;
//     width: 100%;
//     font-size: 16px;
//   }
// }
</style>
<script>
import productMixin from "~/mixins/product";
import ProductPrice from "@/components/product/ProductPrice";
import ProductPromo from "@/components/product/ProductPromo";
import LongPress from "vue-directive-long-press";

import clickHandler from "~/mixins/clickHandler";

import { mask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";
import get from "lodash/get";

export default {
  name: "CartItem",
  components: { ProductPrice, ProductPromo },
  props: ["item", "full", "itemIndex", "isCheckout"],
  mixins: [productMixin, clickHandler],
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  data() {
    return { removeDialog: false, tempUserNotes: "" };
  },
  computed: {
    product() {
      return this.item.product;
    },
    classCardContainerObject: function() {
      if (this.isCheckout && this.$vuetify.breakpoint.mdAndUp) {
        return "isCheckout d-flex flex-column justify-space-around";
      } else {
        if (!this.$vuetify.breakpoint.xs) {
          return "d-flex flex-row justify-space-around";
        }
        return "XS";
      }
    },
    acceptAlternative: {
      get() {
        return (
          get(this.item, "cartItemInfo.accept_alternatives", "true") == "true"
        );
      },
      set(value) {
        this.setCartItemInfo({
          items: this.item,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    userNotes: {
      get() {
        return this.item.cartItemInfo.user_notes;
      },
      set(value) {
        this.tempUserNotes = value;
      }
    },
    ...mapGetters({
      isMasterAccount: "cart/isMasterAccount",
      isSsoUser: "cart/isSsoUser"
    })
  },
  methods: {
    ...mapActions({ setCartItemInfo: "cart/setCartItemInfo" }),
    remove() {
      this.removeDialog = true;
    },
    doRemove() {
      this.removeDialog = false;
      this.removeItem(this.item);
    }
  },
  cerated() {
    console.log(this, this.item);
  }
};
</script>
