const routes = [
  {
    path: "/page/:pageName",
    name: "Page",
    component: () => import("@/views/wordpress/Page.vue")
  },
  {
    path: "/volantini",
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "Flyers",
        meta: { pageName: "volantini" },
        component: () => import("@/views/wordpress/Flyers.vue")
      },
      {
        path: "/volantini/:volantino",
        name: "Flyer",
        meta: { pageName: "volantini" },
        component: () => import("@/views/wordpress/Flyer.vue")
      }
    ]
  },
  {
    path: "/area-didattica-livelab",
    meta: {
      breadCrumb: "Area didattica – GrosMarket LiveLab"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "Events",
        path: "",
        component: () => import("@/views/wordpress/Events.vue"),
        meta: {
          pageName: "area-didattica"
        }
      },
      {
        path: "/area-didattica-livelab/eventi-passati",
        name: "PastEvents",
        meta: {
          breadCrumb: "Eventi passati"
        },
        props: route => ({
          pageNum: route.query.page,
          tag: route.query.tag
        }),
        component: () => import("@/views/wordpress/PastEvents.vue")
      },
      {
        path: "/area-didattica-livelab/:event",
        name: "Event",
        meta: {
          breadCrumb: "Dettaglio Evento"
        },
        component: () => import("@/views/wordpress/Event.vue")
      }
    ]
  },
  {
    path: "/faq",
    meta: {
      breadCrumb: "Faq"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "FaqCategory",
        path: "",
        meta: {
          pageName: "faq"
        },
        component: () => import("@/views/wordpress/FaqList.vue")
      },
      {
        path: ":categoryName",
        name: "FaqList",
        meta: {
          breadCrumb: "Dettaglio Faq"
        },
        component: () => import("@/views/wordpress/FaqList.vue")
      }
    ]
  }
];

export default routes;
