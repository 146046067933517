<template>
  <v-container class="pa-0 category-tree-container">
    <div class="header d-flex justify-space-between align-center">
      <h3 class="text-white">{{ $t("categories.title") }}</h3>
      <v-btn
        class="close-btn"
        large
        plain
        @click="$emit('close')"
        color="white"
      >
        x
      </v-btn>
    </div>
    <v-list dense class="category-tree">
      <v-list-group
        v-for="category in categories"
        :key="category.categoryId"
        class="category1 px-0"
      >
        <template v-slot:activator>
          <v-list-item-title>
            <router-link
              :to="{ name: 'Category', params: { pathMatch: category.slug } }"
              class="px-4 text-white text-uppercase"
              event
              @click.native.prevent="nop"
            >
              {{ category.name }}</router-link
            ></v-list-item-title
          >
        </template>
        <template v-for="category2 in category.categories">
          <v-list-group
            sub-group
            color="#033"
            class="category2"
            v-if="category2.categories"
            :key="category2.categoryId"
          >
            <template v-slot:activator>
              <v-list-item-icon
                v-if="category.metaData && category.metaData.category_info.ICON"
              >
                <v-img :src="category.metaData.category_info.ICON"></v-img>
              </v-list-item-icon>
              <v-list-item-title>
                <router-link
                  :to="{
                    name: 'Category',
                    params: { pathMatch: category2.slug }
                  }"
                  event
                  class="pl-10 pr-4"
                  @click.native.prevent="nop"
                  >{{ category2.name }}
                </router-link>
              </v-list-item-title>
            </template>

            <v-list-item
              v-for="category3 in category2.categories"
              :key="category3.categoryId"
              link
              :to="{ name: 'Category', params: { pathMatch: category3.slug } }"
              color="grey lighten-3"
              class="category3"
            >
              <v-list-item-title>{{ category3.name }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            link
            :to="{ name: 'Category', params: { pathMatch: category2.slug } }"
            color="grey lighten-3"
            class="category2"
            :key="category2.categoryId"
          >
            <v-list-item-title>{{ category2.name }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list-group>
    </v-list>
  </v-container>
</template>
<style lang="scss"></style>
<script>
import deliveryReactive from "~/mixins/deliveryReactive";
import { mapState, mapActions } from "vuex";
export default {
  name: "CategoryTree",
  mixins: [deliveryReactive],
  data() {
    return { expandedKeys: [], selectedKey: null };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    })
  },
  methods: {
    ...mapActions({
      reload: "category/loadCategoryTree"
    }),
    close() {
      this.$emit("close");
    },
    nop() {}
  },
  created() {
    this.reload();
  }
};
</script>
