import "./plugins";
import "./config";
import "vuetify-dialog/dist/vuetify-dialog.css";

import App from "./App.vue";
import TextHighlight from "vue-text-highlight";
import Vue from "vue";
import VuetifyDialog from "vuetify-dialog";
import cssVars from "css-vars-ponyfill";
import i18n from "./plugins/i18n";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./scss/app.scss";

import DialogLayout from "@/components/DialogLayout";
Vue.config.productionTip = false;

cssVars({});

global.EventBus = new Vue({
  i18n
});

Vue.component("text-highlight", TextHighlight);

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
    store,
    router,
    i18n
  },
  confirm: {
    actions: {
      false: "No",
      true: {
        text: "Si",
        color: "primary"
      }
    },
    icon: true
  }
});

// && process.env.NODE_ENV === "production"
// if (Vue.analytics.fbq) {
//   Vue.analytics.fbq.init(process.env.VUE_APP_FB_PIXEL);
// }
Vue.mixin({
  computed: {
    $platform_is_cordova: () => typeof cordova !== "undefined"
  }
});
const vm = new Vue({
  router,
  store,
  vuetify,
  i18n,

  render: h => h(App)
}).$mount("#app");

global.vm = vm;

const toastedOption = {
  position: "bottom-center",
  duration: 3000,
  iconPack: "mdi",
  theme: "bubble",
  keepOnHover: true,
  action: {
    icon: "mdi-close",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  }
};
global.EventBus.$on("error", payload => {
  Vue.toasted.error(global.EventBus.$t(payload.message), toastedOption);
});

global.EventBus.$on("success", payload => {
  Vue.toasted.success(global.EventBus.$t(payload.message), toastedOption);
});

global.EventBus.$on("confirm", payload => {
  global.vm.$dialog.confirm({
    text: global.EventBus.$t(payload.message),
    title: global.EventBus.$t(payload.title),
    actions: {
      false: global.EventBus.$t("common.no"),
      true: {
        color: "red",
        text: global.EventBus.$t("common.yes"),
        handle: () => {
          if (payload.callback) payload.callback(true);
        }
      }
    }
  });
});

global.EventBus.$on("notification", payload => {
  global.vm.$dialog.confirm({
    text: payload.message,
    title: payload.title,
    icon: false,
    actions: {
      login: {
        flat: true,
        text: global.EventBus.$t("common.yes"),
        handle: () => {
          if (payload.callback) payload.callback(true);
        }
      }
    }
  });
});

Vue.prototype.$dialog.layout("default", DialogLayout);
