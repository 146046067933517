var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"address-selector fill-height mt-1"},[_c('v-layout',{attrs:{"fill-height":"","column":""}},[(_vm.category && _vm.category.metaData && _vm.category.metaData.category_info)?_c('h3',{staticClass:"title font-weight-bold"},[_vm._v(" "+_vm._s(_vm.category.metaData.category_info.TITLE)+" ")]):_vm._e(),(
        _vm.category &&
          _vm.category.metaData &&
          _vm.category.metaData.category_info &&
          _vm.category.metaData.category_info.DESCRIPTION
      )?_c('p',{staticClass:"selector-subitle",domProps:{"innerHTML":_vm._s(_vm.category.metaData.category_info.DESCRIPTION)},on:{"click":_vm.clicked}}):_vm._e(),_c('v-row',{staticClass:"mb-7 align-center justify-space-between",attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? 100 : 110}},_vm._l((_vm.deliveryServices),function(service){return _c('v-col',{key:service.deliveryServiceId,staticClass:"d-flex align-center",attrs:{"cols":"12","sm":_vm.deliveryServiceButtonsLength},on:{"click":function($event){return _vm.fetchAddresses(service)}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-spacer'),_c('div',{staticClass:"address-div d-flex flex-column align-center justify-center flex-grow-1 text-center",class:{
            selected:
              _vm.deliveryService.deliveryServiceId === service.deliveryServiceId
          }},[_c('img',{staticClass:"mb-2",attrs:{"height":"50","width":"70","src":_vm.getServiceImg(service.deliveryServiceId)}}),_c('span',{staticClass:"primary--text--lighten-5",class:{
              selected:
                _vm.deliveryService.deliveryServiceId ===
                service.deliveryServiceId
            }},[_vm._v(_vm._s(service.name))])]),_c('v-spacer')],1)}),1),_c('div',{staticClass:"service-description",domProps:{"innerHTML":_vm._s(_vm.deliveryService.descr)}}),_c('v-card',{attrs:{"loading":_vm.loading,"flat":""}},[(_vm.filteredAddresses.length > 0)?_c('v-list',{staticClass:"address-list",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.shippingAddress),callback:function ($$v) {_vm.shippingAddress=$$v},expression:"shippingAddress"}},_vm._l((_vm.filteredAddresses),function(address,index){return _c('v-list-item',{key:address.addressId,attrs:{"two-line":""},on:{"click":function($event){return _vm.setAddress(address)}}},[_c('v-list-item-avatar',[(_vm.shippingAddress == index)?_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline")]):_c('v-icon',[_vm._v("mdi-checkbox-blank-circle-outline")])],1),_c('v-list-item-content',{staticClass:"align-self-start"},[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(address.addressName)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + address.addressTypeId + ".list", address ))+" ")])],1),_c('v-list-item-action',{staticClass:"flex-row align-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                      address.addressId == _vm.cart.user.defaultStoreAddressId ||
                        address.addressId ==
                          _vm.cart.user.defaultDeliveryAddressId
                    )?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-star-outline")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(address.addressType == "home" ? "Indirizzo preferito" : "Negozio preferito"))])])],1)],1)}),1)],1):_c('v-alert',{staticClass:"mt-2",attrs:{"border":"left","type":"error"}},[_vm._v(_vm._s(_vm.$t("message.requiredAddress")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }