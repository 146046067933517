<template>
  <v-dialog
    ref="vdialog"
    eager
    :contentClass="extraClass ? extraClass + ' vuedl-layout' : 'vuedl-layout'"
    v-model="isActive"
    :fullscreen="fullscreen"
    :max-width="getWidth"
    :persistent="persistent || loading"
    :scrollable="scrollable"
    :transition="transition"
    :hide-overlay="hideOverlay"
    :retain-focus="false"
    @keydown.esc="dismiss"
  >
    <v-card>
      <!-- color="primary" -->
      <v-toolbar dark :color="toolbarColor" elevation="0" height="100px">
        <v-spacer></v-spacer>
        <v-toolbar-title v-if="!hideToolbarLogo"
          ><img src="/logo-white.svg" alt="Logo Grossmarket"
        /></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            v-if="showClose && !persistent && !loading"
            icon
            depressed
            dark
            @click.stop="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <dialog-child ref="dialog" v-bind="$options.propsData" />
    </v-card>
  </v-dialog>
</template>

<script>
import { VDialog } from "vuetify/lib";

export default {
  components: {
    VDialog
  },
  props: {
    fullscreen: Boolean,
    scrollable: Boolean,
    hideOverlay: Boolean,
    transition: {
      type: [String, Boolean],
      default: "dialog-transition"
    },
    showClose: {
      type: Boolean,
      default: () => true
    },
    toolbarColor: { type: String, default: "primary" },
    hideToolbarLogo: {
      type: Boolean,
      default: () => false
    },
    extraClass: String
  },
  methods: {
    _destroy() {
      // Allow to draw transition, cause vuetify doesn't have onClose method
      setTimeout(() => {
        this.$destroy();
      }, 1000);
      // this.$refs.vdialog.$refs.dialog.addEventListener('transitionend', this.onTransitionEnd)
    }
    // onTransitionEnd (event) {
    //   if (['opacity', 'z-index'].indexOf(event.propertyName) >= 0) {
    //     this.$refs.vdialog.$refs.dialog.removeEventListener('transitionend', this.onTransitionEnd)
    //     this.$destroy()
    //   }
    // }
  }
};
</script>
<style>
.vuedl-layout {
  position: relative;
}
.vuedl-layout__closeBtn {
  position: absolute;
  top: 16px;
  width: 16px;
  height: 16px;
  font-family: -webkit-pictograph;
  font-size: 30px;
  opacity: 0.5;
  z-index: 1000;
  cursor: pointer;
  line-height: 0.5;
}
.v-application--is-ltr .vuedl-layout__closeBtn {
  right: 14px;
}
.v-application--is-rtl .vuedl-layout__closeBtn {
  left: 14px;
}
.vuedl-layout__closeBtn:hover {
  opacity: 1;
}
</style>
