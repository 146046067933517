import Vue from "vue";
import Vuex from "vuex";

import app from "~/store/app";
import cart from "~/store/cart";
import category from "~/store/category";
import wordsSearched from "~/store/wordsSearched";
import custom from "./custom";

import VuexPersistence from "vuex-persist";
import createLogger from "vuex/dist/logger";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["app", "wordsSearched", "custom"],
  reducer: state => ({
    app: {
      drawerLeft: state.app.drawerLeft,
      // drawerLeftSite: state.app.drawerLeftSite,
      lastStartTime: state.app.lastStartTime,
      lastTutorialSeen: state.app.lastTutorialSeen,
      cookiePreferences: state.app.cookiePreferences,
      ordersSentToAnalytics: state.app.ordersSentToAnalytics,
      reopenCookies: state.app.reopenCookies
    },
    wordsSearched: state.wordsSearched,
    custom: {
      priceDisplayDisclaimer: state.custom.priceDisplayDisclaimer
    }
  }),
  filter: mutation =>
    mutation.type != "app/increasePendingRequests" ||
    mutation.type == "app/decreasePendingRequests"
});
const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ["category", "cart"],
  reducer: state => ({
    category: {
      category: state.category.category,
      filters: state.category.filters
    },
    cart: {
      ssoUser: state.cart.ssoUser
    }
  })
});

const modules = {
  modules: {
    app,
    cart,
    category,
    wordsSearched,
    custom
  },
  strict: process.env.NODE_ENV !== "production",
  plugins:
    process.env.NODE_ENV !== "production"
      ? [createLogger(), vuexLocal.plugin, vuexSession.plugin]
      : [vuexLocal.plugin, vuexSession.plugin]
};

Vue.use(Vuex);
const store = new Vuex.Store(modules);
export default store;
