<template>
  <v-container class="timeslot-selector fill-height mt-1">
    <v-layout fill-height column>
      <div
        class="d-flex flex-column justify-center mb-6"
        v-if="category.metaData && category.metaData.category_info"
      >
        <h2 class="selector-title align-center mb-8">
          {{ category.metaData.category_info.TITLE }}
        </h2>
        <!-- <h5 class="subtitle-1" v-if="category.metaData.category_info.SUBTITLE">
          {{ category.metaData.category_info.SUBTITLE }}
        </h5> -->
        <!-- <div
          v-if="category.metaData.category_info.DESCRIPTION"
          v-html="category.metaData.category_info.DESCRIPTION"
        ></div> -->
        <div class="selector-subtitle">
          <p class="mb-2">
            <strong>{{ $t("timeslots.deliverySelected") }}</strong>
            <!-- :disabled="!editableAddress" -->
            <v-btn
              class="modify-address pl-0"
              text
              tile
              small
              elevation="0"
              rounded
              @click="openAddressSelector"
            >
              <img class="ml-1" :src="serviceImg" />
              <span class="service-name-label service-name ml-2"
                >&nbsp;{{ serviceName }}</span
              >
              <span class="edit ml-2">({{ $t("timeslots.addressEdit") }})</span>
            </v-btn>
            <!-- {{
              $t(
                "navbar.decodeDeliveryService." +
                  cart.shippingAddress.addressType
              )
            }} -->
          </p>
          <p class="mb-2">
            <strong v-if="cart.shippingAddress.addressType == 'home'">{{
              $t("timeslots.addressSelectedDelivery")
            }}</strong>
            <strong v-else>{{ $t("timeslots.addressSelectedDrive") }}</strong>
            {{
              cart.shippingAddress.addressName +
                " - " +
                $t(
                  "navbar.address." +
                    cart.shippingAddress.addressTypeId +
                    ".list",
                  cart.shippingAddress
                )
            }}
          </p>
        </div>

        <!-- <v-icon small color="primary" right @click="changeAddress">
            $edit
          </v-icon> -->
      </div>

      <!-- <v-divider></v-divider> -->
      <v-tabs
        hide-slider
        icons-and-text
        v-model="tab"
        :height="$vuetify.breakpoint.smAndDown ? 80 : 110"
        center-active
        show-arrows
        v-if="timeslots.length > 0"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="timeslotDay in timeslots" :key="timeslotDay.dateIso">
          <div
            class="time-div d-flex flex-column align-center justify-space-around"
          >
            <template v-if="$dayjs().isSame(timeslotDay.dateIso, 'day')">
              <span class="day-number font-weight-bold">
                {{ $t("timeslots.today") }}
              </span>
            </template>
            <template v-else>
              <span class="day-string">
                {{ $dayjs(timeslotDay.dateIso).format("dddd") }}
              </span>
              <span class="day-number font-weight-bold">
                {{ $dayjs(timeslotDay.dateIso).format("D") }}
              </span>
              <span class="month">
                {{ $dayjs(timeslotDay.dateIso).format("MMMM") }}
              </span>
            </template>
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs v-else-if="loading === false">
        Non ci sono fasce disponibili per questa zona
      </v-tabs>
      <!-- <v-divider></v-divider> -->
      <v-tabs-items v-model="tab" class="mb-8 mt-5">
        <v-tab-item
          class="timeslot-container"
          v-for="timeslotDay in timeslots"
          :key="timeslotDay.dateIso"
        >
          <v-row no-gutters>
            <v-col
              cols="6"
              md="3"
              v-for="timeslot in timeslotDay.timeslots"
              :key="timeslot.timeslotId"
            >
              <v-card
                class="timeslot-card"
                flat
                :disabled="disabled"
                @click="selectTimeslot(timeslotDay, timeslot)"
              >
                <div
                  class="timeslot-button d-flex justify-center"
                  v-bind:class="[
                    timeslot.status,
                    timeslot.selected && isTimeslotAssigned ? 'selected' : ''
                  ]"
                >
                  <!-- <span v-if="!isDelivery" class="timeslot-time"
                    >{{ timeslot.beginTime }} - {{ timeslot.endTime }}</span
                  > -->
                  <span class="timeslot-time">
                    {{ timeslot.name }}
                  </span>
                  <!-- <span
                    v-if="timeslot.status === 'intermediate'"
                    class="text-status"
                    >{{ $t("timeslots.slotAttribute.slotIntemediate") }}</span
                  > -->
                  <!-- <span v-if="timeslot.status === 'full'" class="text-status">{{
                    $t("timeslots.slotAttribute.slotFull")
                  }}</span> -->
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
      <v-divider></v-divider>
      <!-- <v-spacer></v-spacer> -->
      <v-row class="legend align-center" no-gutters>
        <v-spacer></v-spacer>
        <v-col cols="4" sm="2" class="py-0">
          <div class="timeslot-legend empty">
            <span>{{ $t("timeslots.slotAttribute.empty") }}</span>
          </div>
        </v-col>
        <!-- <v-col cols="3" sm="2" class="py-0">
          <div class="timeslot-legend intermediate">
            <span>{{ $t("timeslots.slotAttribute.intermediate") }}</span>
          </div>
        </v-col> -->
        <v-col cols="4" sm="2" class="py-0">
          <div class="timeslot-legend full">
            <span>{{ $t("timeslots.slotAttribute.full") }}</span>
          </div>
        </v-col>
        <v-col cols="4" sm="2" class="py-">
          <div class="timeslot-legend selected">
            <span>{{ $t("timeslots.slotAttribute.selected") }}</span>
          </div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-layout>
  </v-container>
</template>
<script>
// @ is an alias to /src
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";
import cartInfo from "~/mixins/cartInfo";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "TimeslotSelector",
  mixins: [cartInfo],
  data() {
    return {
      timeslots: [],
      tab: 0,
      disabled: false,
      category: {},
      loading: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      isTimeslotAssigned: "cart/isTimeslotAssigned"
    }),
    isDelivery() {
      return this.cart.shippingAddress.addressType == "home";
    }
  },
  methods: {
    ...mapActions({
      setTimeslot: "cart/setTimeslot"
    }),
    openAddressSelector() {
      this.$emit("submit", "setAddress");
    },
    checkIfIsToday(date) {
      return this.$dayjs().isSame(date, "day");
    },
    async selectTimeslot(timeslotDay, timeslot) {
      if (timeslot.active < 1) {
        return;
      }

      this.disabled = true;
      let data = await this.setTimeslot({
        dateIso: timeslotDay.dateIso,
        timeslotId: timeslot.timeslotId
      });
      this.disabled = false;
      if (data) {
        this.$emit("submit", true);
      }
    },
    async fetchTimeslots() {
      let vm = this;
      vm.loading = true;
      let days = await DeliveryService.getTimeslotList(false);
      vm.timeslots = days.filter(day => day.timeslots.length > 0);

      for (let i = 0; i < vm.timeslots.length; i++) {
        if (vm.timeslots[i].dateIso == vm.cart.timeslot.date) {
          vm.tab = i;
          vm.selectedTimeslotDay = vm.timeslots[i];
          break;
        }
      }
      vm.loading = false;
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "timeslot-selector"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },

  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.disabled = false;
    this.fetchTimeslots();
    this.fetchCategory();
    // this.getDayTabName();
  }
};
</script>
