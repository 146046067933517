<template>
  <div class="footer-wrapper" @click="clicked">
    <!-- LINK DA CMSERVICE -->
    <div class="primary">
      <v-container class="links">
        <v-row>
          <v-col
            sm="6"
            md="3"
            v-for="(item, index) in links"
            :key="item.ID"
            class="justify-center align-center text-center text-md-left"
          >
            <div class="d-flex flex-column" v-if="!item.classes">
              <div class="footer-title text-uppercase white--text">
                {{ item.title }}
              </div>
              <a
                v-for="child in item.children"
                :key="child.ID"
                :href="child.url"
                class="white--text"
                :class="child.classes"
              >
                {{ child.title }}
              </a>
              <span
                v-if="index == 0"
                link
                class="eyeable-management white--text"
                @click="openEyeablePanel()"
              >
                {{ $t("footer.links.eyeable") }}
              </span>
              <span
                v-if="index == 0"
                link
                class="cookie-managment white--text"
                @click="reopenCookies()"
              >
                {{ $t("footer.links.cookies") }}
              </span>
            </div>
            <div
              v-else
              class="d-flex align-center justify-center ppppppp"
              style="height: 100%"
            >
              <v-btn
                :class="item.classes"
                elevation="0"
                :href="item.children[0] ? item.children[0].url : item.url"
                >{{ item.title }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container
      class="address d-flex flex-column justify-center align-center mb-5 mb-sm-0"
    >
      <span>
        © Sogegross S.p.A.
      </span>
      <span>
        Lungotorrente Secca, 3A, 16163 Genova
      </span>
      <span>
        P.I.: 01226470993 - R.I. Genova n.01226470993
      </span>
      <span>
        Cap.Soc. € 4.000.000,00 i.v.
      </span>
      <i18n path="common.version" tag="span">{{ version }}</i18n>
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.footer-wrapper {
  width: 100%;
}
.links {
  padding: 50px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 10px;
  }

  .footer-title {
    font-size: 20px;
    font-weight: 600;
  }

  a,
  .cookie-managment,
  .eyeable-management {
    font-size: 12px;
    line-height: 24px;
    text-decoration: none;
  }

  .cookie-managment,
  .eyeable-management {
    cursor: pointer;
  }

  .btn_outlined {
    background: transparent;
    border: 1px solid $white;
    color: $white;
    min-width: 180px;
    border-radius: $border-radius-root;
    font-size: 12px;
  }

  .btn_filled {
    background: $white;
    border: 1px solid $white;
    color: $primary;
    min-width: 180px;
    border-radius: $border-radius-root;
    font-size: 12px;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 50px 10px 45px;
    .title {
      font-size: 16px !important;
    }
  }
}

.customer {
  background-size: cover;
  height: 266px;
  h3 {
    color: $secondary;
  }
  .v-btn {
    border-radius: $border-radius-root;
    width: 341px;
    height: 41px;
    font-size: 12px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      max-width: 170px;
    }
  }
}

.address {
  padding: 40px 0 43px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-bottom: 150px !important;
  }
  span {
    font-size: 12px;
    line-height: 18px;
    color: #636363;
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import login from "~/mixins/login";
import { mapActions } from "vuex";
export default {
  name: "Footer",
  props: ["links"],
  mixins: [clickHandler, login],
  computed: {
    version() {
      return global.config.version;
    }
  },
  methods: {
    ...mapActions({
      updatedReopenCookies: "app/updatedReopenCookies"
    }),
    reopenCookies() {
      if (window.Cookiebot) {
        window.Cookiebot.show();
      }
    },
    openEyeablePanel() {
      if (window.EyeAbleAPI) {
        window.EyeAbleAPI.toggleToolbar();
      }
    }
  }
};
</script>
