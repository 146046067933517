const UPDATED_DRAWER_LINKS = "updatedDrawerLinks";
const UPDATED_DRAWER_SEARCH = "updatedDrawerSearch";
const SET_LIST_FILTER_FACETS = "SET_LIST_FILTER_FACETS";
const SET_LIST_FILTER_FACETS_MOBILE = "SET_LIST_FILTER_FACETS_MOBILE";
const SET_LIST_FILTER_SORT = "SET_LIST_FILTER_SORT";
const SET_LIST_FILTER_PROMO = "SET_LIST_FILTER_PROMO";
const SET_LIST_FILTER_NEW = "SET_LIST_FILTER_NEW";
const SET_LIST_FILTER_PAGE = "SET_LIST_FILTER_PAGE";
const SET_LIST_FILTER_CATEGORY_ID = "SET_LIST_FILTER_CATEGORY_ID";
const RESET_LIST_FILTERS = "RESET_LIST_FILTERS";
const SET_LIST_PAGE_SIZE = "SET_LIST_PAGE_SIZE";

const state = {
  drawerLinks: null,
  drawerSearch: null,
  listFilters: {
    facets: {},
    sort: null,
    promo: false,
    onlyNew: false,
    page: 1,
    categoryId: null,
    pageSize: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE
  }
};

const actions = {
  [UPDATED_DRAWER_LINKS]({ commit }, value) {
    commit(UPDATED_DRAWER_LINKS, value);
  },
  [UPDATED_DRAWER_SEARCH]({ commit }, value) {
    commit(UPDATED_DRAWER_SEARCH, value);
  },
  setFilterFacets({ commit }, { facets, parameterName }) {
    commit(SET_LIST_FILTER_FACETS, { facets, parameterName });
  },
  setFilterFacetsMobile({ commit }, { facets }) {
    commit(SET_LIST_FILTER_FACETS_MOBILE, facets);
  },
  setFilterSort({ commit }, sort) {
    commit(SET_LIST_FILTER_SORT, sort);
  },
  setFilterPromo({ commit }, promo) {
    commit(SET_LIST_FILTER_PROMO, promo);
  },
  setFilterNew({ commit }, onlyNew) {
    commit(SET_LIST_FILTER_NEW, onlyNew);
  },
  setFilterPage({ commit }, page) {
    commit(SET_LIST_FILTER_PAGE, page);
  },
  setPageSize({ commit }, pageSize) {
    commit(SET_LIST_PAGE_SIZE, pageSize);
  },

  resetFilters({ commit, state }, categoryId) {
    if (state.filters.categoryId != categoryId) {
      commit(RESET_LIST_FILTERS);
      commit(SET_LIST_FILTER_CATEGORY_ID, categoryId);
    }
  }
};

const getters = {
  facetsFilter: state => {
    return state.listFilters.facets;
  },
  promoFilter: state => {
    return state.listFilters.promo;
  },
  newFilter: state => {
    return state.listFilters.onlyNew;
  },
  sortFilter: state => {
    return state.listFilters.sort;
  },
  pageFilter: state => {
    return state.listFilters.page;
  },
  pageSize: state => {
    return state.listFilters.pageSize;
  }
};

const mutations = {
  [UPDATED_DRAWER_LINKS]: (state, value) => {
    state.drawerLinks = value;
  },
  [UPDATED_DRAWER_SEARCH]: (state, value) => {
    state.drawerSearch = value;
  },
  [SET_LIST_FILTER_FACETS](state, { facets, parameterName }) {
    state.listFilters.facets[parameterName] = facets;
    state.listFilters.page = 1;
  },
  [SET_LIST_FILTER_FACETS_MOBILE](state, facets) {
    state.listFilters.facets = facets;
    state.listFilters.page = 1;
  },
  [SET_LIST_FILTER_SORT](state, value) {
    state.listFilters.sort = value;
  },
  [SET_LIST_FILTER_PROMO](state, value) {
    state.listFilters.promo = value;
    state.listFilters.page = 1;
  },
  [SET_LIST_FILTER_NEW](state, value) {
    state.listFilters.onlyNew = value;
    state.listFilters.page = 1;
  },
  [SET_LIST_FILTER_PAGE](state, value) {
    state.listFilters.page = value;
  },
  [SET_LIST_PAGE_SIZE](state, value) {
    state.listFilters.pageSize = value;
  },
  [SET_LIST_FILTER_CATEGORY_ID](state, value) {
    state.listFilters.categoryId = value;
  },
  [RESET_LIST_FILTERS](state) {
    state.listFilters = {
      facets: {},
      sort: null,
      promo: false,
      onlyNew: false,
      page: 1,
      pageSize: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE
    };
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
