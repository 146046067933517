<template>
  <v-bottom-navigation grow fixed dark class="nav px-3 primary bottom-nav-bar">
    <!-- drawer sinistro -->
    <v-btn @click.prevent.stop="drawerLeft = !drawerLeft">
      <span>{{ $t("navbar.products") }}</span>
      <v-icon>$menu</v-icon>
    </v-btn>
    <!-- ricerca -->
    <v-btn @click.prevent.stop="openSearch">
      <span>{{ $t("navbar.search") }}</span>
      <v-icon>$search</v-icon>
    </v-btn>
    <!-- liste -->
    <v-btn v-if="isAuthenticated" to="/profile/lists">
      <span>{{ $t("navbar.lists") }}</span>
      <v-icon>$heart</v-icon>
    </v-btn>
    <!-- promo -->
    <v-btn
      :to="{
        name: 'Category',
        params: { pathMatch: 'promo' }
      }"
    >
      <span>{{ $t("navbar.promo") }}</span>
      <v-icon>$promo</v-icon>
    </v-btn>
    <!-- drawer destro -->
    <v-btn @click.prevent.stop="drawerLinks = !drawerLinks">
      <span>&nbsp;</span>
      <v-icon>$rightmenu</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import login from "~/mixins/login";
import search from "@/mixins/search";
import { mapActions, mapState } from "vuex";
export default {
  mixins: [login, search],
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerLinks: "custom/updatedDrawerLinks",
      updatedDrawerSearch: "custom/updatedDrawerSearch"
    }),
    goToPromoPage() {
      this.$store.dispatch("category/setFilterPromo", true);
      this.$router.push({
        path: "category/promo"
      });
    },
    openSearch() {
      this.openSearchPanel();
      global.EventBus.$emit("focusSearch");
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerLinks: state => state.custom.drawerLinks,
      storeDrawerSearch: state => state.custom.drawerSearch,
      cart: ({ cart }) => cart.cart
    }),
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    drawerSearch: {
      get() {
        return this.storeDrawerSearch;
      },
      set(value) {
        this.updatedDrawerSearch(value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.nav {
  height: $bottom-navigation-bar-height;
  background: $primary !important;
  position: fixed;
  bottom: 0;
  height: calc(
    #{$bottom-navigation-bar-height} + constant(safe-area-inset-top) + 20px
  ) !important;
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  height: calc(
    #{$bottom-navigation-bar-height} + env(safe-area-inset-top) + 20px
  ) !important;
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  .v-btn {
    font-size: 0.85rem !important;
    .v-btn__content {
      color: $white !important;
    }
  }
}
.keyboard-open {
  .bottom-nav-bar {
    display: none !important;
  }
}
</style>
