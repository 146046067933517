/* eslint-disable no-debugger */
import SearchPanel from "@/components/navigation/SearchPanel";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      config: global.config,
      dialogSearchInstance: null
    };
  },
  computed: {
    ...mapGetters({})
  },
  methods: {
    async openDialog(component, properties, fullscreen) {
      var defaultConfig = {
        waitForResult: false,
        hideToolbarLogo: true,
        scrollable: false,
        toolbarColor: "secondary",
        extraClass: "search-panel",
        fullscreen:
          fullscreen !== undefined
            ? fullscreen
            : this.$vuetify.breakpoint.smAndDown
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogSearchInstance = await this.$dialog.show(component, config);
      const result = await this.dialogSearchInstance.wait();
      return result;
    },
    async closeDialog() {
      await this.dialogSearchInstance.close();
      return true;
    },
    async openSearchPanel() {
      let result = await this.openDialog(SearchPanel, {
        waitForResult: true,
        width: 750
      });
      return result;
    }
  },
  created() {
    this.config = global.config;
  }
};
