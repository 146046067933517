var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"menu",staticClass:"masonry-category-menu"},[_c('div',{staticClass:"d-flex masonry-row"},[_c('div',{staticClass:"all-products",attrs:{"flat":""}},[_c('v-list',{attrs:{"flat":"","dense":"","height":_vm.listHeight}},[_c('v-list-item-group',{model:{value:(_vm.selectedCategoryFirstLevel),callback:function ($$v) {_vm.selectedCategoryFirstLevel=$$v},expression:"selectedCategoryFirstLevel"}},_vm._l((_vm.categories),function(category){return _c('v-list-item',{key:category.categoryId,nativeOn:{"click":function($event){$event.preventDefault();return _vm.selectCategory(category)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('router-link',{attrs:{"to":{
                    name: 'Category',
                    params: { pathMatch: category.slug }
                  },"event":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.selectCategory(category)}}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)],1)],1)}),1)],1)],1),(_vm.categorySelected)?_c('div',{staticClass:"category2",attrs:{"flat":"","cols":undefined}},[_c('div',{staticClass:"masonry-layout masonry-layout-col-3 masonry-layout-md-col-2"},_vm._l((_vm.categorySelected.categories),function(category2,index){return _c('v-list',{key:index,staticClass:"masonry-list masonry-layout__panel",attrs:{"dense":""}},[_c('v-list-item-title',{staticClass:"subheaders"},[_c('router-link',{key:category2.categoryId,attrs:{"to":{
                name: 'Category',
                params: { pathMatch: category2.slug }
              },"link":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_vm._v(_vm._s(category2.name)+" ")])],1),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((category2.categories),function(category3){return _c('v-list-item',{key:category3.categoryId,attrs:{"active-class":"link-active","link":"","to":{
                name: 'Category',
                params: { pathMatch: category3.slug }
              }},on:{"click":_vm.close}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(category3.name)}})],1)],1)}),1)],1)}),1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }